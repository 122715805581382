import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./widgets/navbar";
import HomePage from "./component/page/home";

// const LazyLandindPage = React.lazy(()=> import('./component/page/landing'))
const LazyAbout = React.lazy(() => import("./component/page/aboutUs"));
const LazyContact = React.lazy(() => import("./component/page/contact"));
const LazyCarrer = React.lazy(() => import("./component/page/carrer"));
const LazyMedia = React.lazy(() => import("./component/page/media"));
const LazyProduct = React.lazy(() =>
  import("./component/page/products/product")
);
const LazyNotFoundPage = React.lazy(() => import("./component/page/notFound"));
const LazyPrivacyPolicy = React.lazy(() =>
  import("./component/page/privacyPolicy")
);
const LazyTermsCondition = React.lazy(() =>
  import("./component/page/termsCondition")
);
const LazyCancelRefundPolicy = React.lazy(() =>
  import("./component/page/cancelRefundPolicy")
);
const LazyFooter = React.lazy(() => import("./widgets/footer"));
const LazyReshitaProduct = React.lazy(() =>
  import("./component/page/products/reshitaProduct")
);

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/about"
            element={
              <React.Suspense fallback="Loading...">
                <LazyAbout />
              </React.Suspense>
            }
          />
          <Route
            path="/product"
            element={
              <React.Suspense fallback="Loading">
                <LazyProduct />
              </React.Suspense>
            }
          />
          <Route
            path="/career"
            element={
              <React.Suspense fallback="Loading...">
                <LazyCarrer />
              </React.Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <React.Suspense fallback="Loading...">
                <LazyContact />
              </React.Suspense>
            }
          />
          <Route
            path="/media"
            element={
              <React.Suspense fallback="Loading...">
                <LazyMedia />
              </React.Suspense>
            }
          />
          <Route
            path="/product"
            element={
              <React.Suspense fallback="Loading...">
                <LazyProduct />
              </React.Suspense>
            }
          />
          <Route
            path="/reshita-product"
            element={
              <React.Suspense fallback="Loading...">
                <LazyReshitaProduct />
              </React.Suspense>
            }
          />
          <Route
            path="*"
            element={
              <React.Suspense fallback="Loading...">
                <LazyNotFoundPage />
              </React.Suspense>
            }
          />
          <Route
            path="/privacyPolicy"
            element={
              <React.Suspense fallback="Loading...">
                <LazyPrivacyPolicy />
              </React.Suspense>
            }
          />
          <Route
            path="/termsCondition"
            element={
              <React.Suspense fallback="Loading...">
                <LazyTermsCondition />
              </React.Suspense>
            }
          />
          <Route
            path="/cancellation&RefundPolicy"
            element={
              <React.Suspense fallback="Loading...">
                <LazyCancelRefundPolicy />
              </React.Suspense>
            }
          />
        </Routes>
        <React.Suspense fallback="Loading...">
          <LazyFooter />
        </React.Suspense>
      </Router>
    </>
  );
};
export default App;
