import React, { useState, useRef, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import ReshitLogo from "../assets/reshitaText.png";
import downSvg from "../assets/down-arrow-backup-2-svgrepo-com.svg"

const Navbar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isProductHovered, setIsProductHovered] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      const visibleSection = entries.find(
        (entry) => entry.isIntersecting
      )?.target;
      if (visibleSection) {
        setActiveSection(visibleSection.id);
      }
    });

    const sections = document.querySelectorAll("[data-section]");
    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  const handleNavLinkClick = () => {
    setOpen(false); // Close the menu when NavLink is clicked
  };

  return (
    <div className="shadow-md montserrat z-50 w-full fixed top-0 left-0">
      <div className="md:flex items-center justify-between bg-white py-4 md:px-10 px-5">
        <div className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800">
          <NavLink to="/" onClick={handleNavLinkClick}>
            <img src={ReshitLogo} className="h-10 w-24" alt="Reshita Logo" />
          </NavLink>
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-9 top-6 cursor-pointer md:hidden"
        >
          <button
            name={open ? "close" : "menu"}
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 mb-4 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            {open ? (
              <div className="text-3xl w-8">X</div>
            ) : (
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            )}
          </button>
        </div>

        <ul
          className={`md:flex md:items-center font-semibold xl:font-medium md:pb-0 pb-12 absolute md:static bg-white left-0 w-full md:w-auto md:pl-0 pl-0 transition-all duration-500 ease-in ${open ? "top-20" : "-top-[490px]"
            }`}
        >
          <li className={`md:ml-8 xl:text-xl py-2 xl:px-2 text-[14px] flex items-center justify-center md:my-0 my-7`} style={{ margin: "0 10px" }}>
            <NavLink to="/" onClick={handleNavLinkClick} className={({ isActive }) => isActive ? "border-b-2 border-blue-500 text-blue-500 md:ml-0" : ""}>
              Home
            </NavLink>
          </li>
          <li className={`md:ml-8 xl:text-xl py-2 xl:px-2 text-[14px] flex items-center justify-center md:my-0 my-7`} style={{ margin: "0 10px" }}>
            <NavLink to="/about" onClick={handleNavLinkClick} className={({ isActive }) => isActive ? "border-b-2 border-blue-500 text-blue-500 md:ml-0" : ""}>
              About Us
            </NavLink>
          </li>
          <li className={`md:ml-8 xl:text-xl py-2 xl:px-2 text-[14px] flex items-center justify-center md:my-0 my-7 relative cursor-pointer`} style={{ margin: "0 10px" }} onMouseEnter={() => setIsProductHovered(true)} onMouseLeave={() => setIsProductHovered(false)}>
            Product  <img className="w-4 ml-2 max-md:ml-1 max-md:w-3" src={downSvg}/>
            {isProductHovered && (
              <ul className="absolute top-full mt-0 w-40 bg-white shadow-lg border border-gray-200">
                <li className="px-4 py-2 hover:bg-gray-200">
                  <NavLink to="/product" onClick={handleNavLinkClick} className={({ isActive }) => isActive ? "border-b-2 border-blue-500 text-blue-500 md:ml-0" : ""}>
                    Doc-aid
                  </NavLink>
                </li>
                <li className="px-4 py-2 hover:bg-gray-200">
                  <NavLink to="/reshita-product" onClick={handleNavLinkClick} className={({ isActive }) => isActive ? "border-b-2 border-blue-500 text-blue-500 md:ml-0" : ""}>
                    Reshita
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li className={`md:ml-8 xl:text-xl py-2 xl:px-2 text-[14px] flex items-center justify-center md:my-0 my-7`} style={{ margin: "0 10px" }}>
            <NavLink to="/media" onClick={handleNavLinkClick} className={({ isActive }) => isActive ? "border-b-2 border-blue-500 text-blue-500 md:ml-0" : ""}>
              Media
            </NavLink>
          </li>
          <li className={`md:ml-8 xl:text-xl py-2 xl:px-2 text-[14px] flex items-center justify-center md:my-0 my-7`} style={{ margin: "0 10px" }}>
            <NavLink to="/career" onClick={handleNavLinkClick} className={({ isActive }) => isActive ? "border-b-2 border-blue-500 text-blue-500 md:ml-0" : ""}>
              Career
            </NavLink>
          </li>
          <li className={`md:ml-8 xl:text-xl py-2 xl:px-2 text-[14px] flex items-center justify-center md:my-0 my-7`} style={{ margin: "0 10px" }}>
            <NavLink to="/contact" onClick={handleNavLinkClick} className={({ isActive }) => isActive ? "border-b-2 border-blue-500 text-blue-500 md:ml-0" : ""}>
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
